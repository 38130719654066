@font-face {
  font-family: 'Marr Sans Web';
  src: url('./assets/fonts/MarrSansWeb-Regular.woff2') format('woff2'),
      url('./assets/fonts/MarrSansWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Atlas Typewriter';
  src: url('./assets/fonts/AtlasTypewriterRegular.woff2') format('woff2'),
      url('./assets/fonts/AtlasTypewriterRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Horizon';
  src: url('./assets/fonts/Horizon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*, *:before, *:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  --accent: hsl(15, 100%, 44%);
  --accentLight: hsl(15, 100%, 44%, 20%);
  --accentXLight: hsl(15, 100%, 44%, 50%);
  --accentDark: hsl(15, 10%, 20%);

  --dimmed: hsla(0,0%,0%,0.4);
  --xDimmed: hsla(0,0%,0%,0.2);
  --border: hsla(0,0%,0%,0.1);
  --background: hsla(0,0%,100%,1);
  --body: hsla(0,0%,0%,1);
  --backdrop: hsla(15, 10%, 90%, 80%);
  
  --text-xl: 2rem;
  --text-l: 1.5rem;
  
  --selectionColor: var(--accent);
  --selectionBackground: var(--accentLight);
  
  --sans: Marr Sans Web;
  --mono: Atlas Typewriter;
  --themeFont: var(--sans);

  --titleSize: var(--text-xl);

  --maxDotSize: 5rem;
  --dotRatio: 2;
  --taskPaddingY: calc(var(--maxDotSize) + 0.5rem);

  --pageMaxWidth: 50rem;
  --pagePaddingX: 1rem;
  --headerPaddingX: 2rem;
}

body.dark {
  --background: hsla(0,0%,10%,1);
  --body: hsla(0,0%,100%,1);
  --backdrop: hsla(15, 0%, 0%, 80%);
  --dimmed: hsla(0, 0%, 100%, 0.5);
  --xDimmed: hsla(0, 0%, 100%, 0.2);
  --border: hsla(0,0%,0%,0.8);
  --selectionColor: var(--body);
  --selectionBackground: var(--accent);
}

body.mono {
  --themeFont: var(--mono);
  --themeFontLS: -0.04em;
}

body.smallText {
  --titleSize: var(--text-l);

  --maxDotSize: 2.5rem;
  --dotRatio: 4;
  --taskPaddingY: calc(var(--maxDotSize) + 1rem);
}

@media (max-width: 800px) {
  :root {
    --titleSize: 1.2rem;
  
    --maxDotSize: 2.5rem;
    --dotRatio: 4;
    --taskPaddingY: calc(var(--maxDotSize) + 1rem);
  }
}

@media (max-width: 960px) {
  :root {
    --headerPaddingX: 0;
  }
}

::selection {
  background-color: var(--selectionBackground);
  color: var(--selectionColor);
}

body {
  background-color: var(--background);
  color: var(--body);
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overscroll-behavior: none;
}

ul {
  list-style: none;
}

h1 {
  font-size: var(--text-xl);
}

h2 {
  font-size: var(--text-l);
}

h1, h2 {
  font-family: var(--themeFont);
  font-weight: normal;
}

a, a:visited {
  color: inherit;
}

hr {
  border-width: 0 0 1px 0;
  border-color: var(--border);
}


#seelaylogo {

  font-family: Horizon;
  text-decoration: none;

}

#seelaylogo:hover {

  color: #E03800;
  transition: 500ms ease;


}

#attribution {
font-size: medium;
}

/* prevent pinch-zoom for Chrome 36+, Safari 13+ */
html {
  touch-action: pan-x pan-y;
  min-height: 100%;
}
/* prevent pinch-zoom at page bottom */